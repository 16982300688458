.container-folder {
    padding: 10px;
    a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        font-weight: 500;
        width: 110px;
        text-decoration: none;
        color: #333;
        svg {
            width: 100px;
            height: 100px;
        }
    }
}
