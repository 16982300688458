.history-data {
    color: #777
}

.history-component {
    padding: 0 40px;
    &__history-item {
        display: flex;
        flex-direction: column;
        padding: 25px 15px;
        position: relative;
        border-left: 1px dashed #333;
        font-size: .8rem;
        color: #666;
        &::after {
            position: absolute;
            width: 10px;
            height: 10px;
            content: "";
            background-color: rgb(255, 255, 255);
            top: 0px;
            left: -5px;
            border-radius: 50%;
            border: 2px solid rgb(105, 105, 105)
        }
    }

    &__history-item:nth-last-child(1)  {
        span:nth-child(1) {
            color: #000;
            font-weight: 400;
        }

        &::before {
            position: absolute;
            width: 10px;
            height: 10px;
            content: "";
            background-color: rgb(255, 255, 255);
            bottom: 0px;
            left: -5px;
            border: 2px solid #000;
        }
    }

    &__history-item:nth-last-child(1)::after  {
        border: 2px solid rgb(30, 186, 30);
    }
}
